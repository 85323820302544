<template>
  <div>
    <b-form @submit.prevent="submitForm" enctype="multipart/form-data">
      <div class="form-row">
        <div class="col-md-8">
          <b-card class="mb-1" header="Informações básicas">
            <b-card-text>
              <div class="form-row">
                <b-col md="6">
                  <b-form-group class="mb-0">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Título
                    </label>
                    <b-form-input
                      v-model="item.title"
                      :class="{ 'is-invalid': $v.item.title.$error }"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group class="mb-0">
                    <label for="">
                      #ID - MIGRAR
                      <feather-icon
                        size="16"
                        class="text-dark"
                        icon="HelpCircleIcon"
                        v-b-tooltip.hover.v-dark
                        title="Caso esteja migrando o produto da versão anterior da Seducar, insira o ID do produto, localizado na tabela de Produtos Digitais > Curso online."
                      />
                    </label>
                    <b-form-input type="number" v-model="item.id_old" />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group class="mb-0">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Código Interno
                      <feather-icon
                        size="16"
                        class="text-dark"
                        icon="HelpCircleIcon"
                        v-b-tooltip.hover.v-dark
                        title="Utilizado para associar o produto ao Consys."
                      />
                    </label>
                    <b-form-input
                      type="text"
                      v-model="item.infos.external_id"
                      :class="{
                        'is-invalid': $v.item.infos.external_id.$error,
                      }"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    class="mt-1"
                    :class="{ 'is-invalid': $v.item.description.$error }"
                  >
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Descrição
                    </label>
                    <editor
                      class="about-editor-1"
                      api-key="22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp"
                      v-model="item.description"
                      :init="
                        this.optionEditor({
                          height: 600,
                          selector: 'about-editor-1',
                        })
                      "
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group class="mb-0 mt-1">
                    <label for=""
                      >Sobre o produto -
                      <small>Será exibido no final da página.</small></label
                    >
                    <editor
                      class="about-editor-2"
                      api-key="22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp"
                      v-model="item.about"
                      :init="
                        this.optionEditor({
                          height: 300,
                          selector: 'about-editor-2',
                        })
                      "
                    />
                  </b-form-group>
                </b-col>
              </div>
            </b-card-text>
          </b-card>

          <b-card
            class="mb-1 card-header-custom-actions"
          >
            <template #header>
              <div class="form-row">
                <div class="col-md-10 col-8">
                  <h4 class="mb-0">
                    <i class="text-danger bi bi-record-circle"></i>
                    Apostilas
                  </h4>
                  <small>As apostilas serão exibidas na página do produto.</small>
                </div>
                <div class="col-md-2 col-4">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    v-b-modal.modal-product
                    class="btn-block"
                  >
                    <i class="bi bi-plus mr-25"></i>
                    <span>Novo</span>
                  </b-button>
                </div>
              </div>
            </template>
    
            <draggable
              tag="ul"
              :list="this.item.books"
              class="list-group"
              handle=".handle"
            >
              <li
                v-for="(element, index) in this.item.books"
                :key="index"
                class="list-group-item"
              >
                <feather-icon icon="MoveIcon" class="handle" size="15" />

                <span class="pl-1 text-not-selected">
                  <b-icon
                    style="width: 9px; margin-right: 6px"
                    icon="circle-fill"
                    :class="element.status ? 'text-success' : 'text-danger'"
                  />
                  {{ element.title }}
                </span>

                <feather-icon
                  icon="XCircleIcon"
                  class="float-right cursor-pointer text-danger"
                  size="20"
                  @click="removeAt(index)"
                />
              </li>
            </draggable>
          </b-card>

          <b-card class="mb-1" header="Sites de buscas">
            <b-card-text>
              <p class="mb-0">
                Caso queira personalizar o título, descrição e url para
                mecanismos de buscas(Googlem Bing e Yahoo), clique em "Editar
                SEO do site" e preencha os campos abaixo.
              </p>

              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="">Título SEO</label>
                    <input
                      v-model="item.title_seo"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label for="">URL</label>
                    <b-input-group
                      :prepend="`/apostilas/`"
                      class="input-group-merge"
                    >
                      <b-form-input
                        class="text-primary pl-1"
                        v-model="item.slug"
                      />
                    </b-input-group>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label for="">Descrição para SEO</label>
                    <textarea
                      v-model="item.description_seo"
                      maxlength="320"
                      class="form-control"
                    ></textarea>
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-card>
        </div>

        <div class="col-md-4">
          <b-card header="Imagem de destaque" class="mb-1">
            <b-card-text>
              <b-row class="form-row">
                <b-col md="12">
                  <input
                    id="image"
                    type="file"
                    accept="image/*"
                    class="form-control-file hidden"
                    @change="previewImage"
                  />
                  <b-form-group>
                    <label
                      for="image"
                      class="d-block text-center"
                      style="
                        border: 1px solid rgb(222 222 222);
                        border-radius: 4px;
                        border-style: dashed;
                        cursor: pointer;
                        font-size: 1.1rem;
                      "
                    >
                      <div v-if="!preview && !image_url" class="pt-5 py-4">
                        <feather-icon size="19" icon="ImageIcon" />
                        Selecionar imagem
                      </div>
                      <template v-if="preview">
                        <b-img
                          for="image"
                          :src="preview"
                          style="width: 100%; height: 200px"
                          fluid
                        />
                      </template>
                      <template v-else-if="!preview && image_url">
                        <b-img
                          for="image"
                          :src="image_url"
                          style="width: 100%; height: 200px"
                          fluid
                        />
                      </template>
                    </label>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label>Vídeo de apresentação</label>
                    <b-form-input
                      type="text"
                      placeholder="Link do YouTube/Vimeo"
                      v-model="item.video_url"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <label>
                    <i class="text-danger bi bi-record-circle"></i>
                    Label da imagem
                  </label>
                  <b-form-group class="mb-0">
                    <b-form-input
                      :class="{ 'is-invalid': $v.item.label_image.$error }"
                      v-model="item.label_image"
                      type="text"
                    />
                  </b-form-group>
                  <small class="d-block" style="margin-top: 4px">
                    Será exibido sobre a imagem de destaque.
                  </small>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>

          <b-card header="Dimensões e códigos" class="mb-1">
            <b-card-text>
              <b-row>
                <b-col md="12">
                  <div class="form-row">
                    <div class="col-6">
                      <b-form-group>
                        <label for="">
                          <i class="text-danger bi bi-record-circle"></i>
                          Peso
                        </label>
                        <b-input-group append="kg">
                          <input
                            v-mask="['####']"
                            type="tel"
                            v-model="item.infos.weight"
                            :class="{
                              'is-invalid': $v.item.infos.weight.$error,
                            }"
                            placeholder="0"
                            class="form-control"
                          />
                        </b-input-group>
                      </b-form-group>
                    </div>

                    <div class="col-6">
                      <b-form-group>
                        <label for="">
                          <i class="text-danger bi bi-record-circle"></i>
                          Largura
                        </label>
                        <b-input-group append="cm">
                          <input
                            v-mask="['####']"
                            type="tel"
                            v-model="item.infos.width"
                            :class="{
                              'is-invalid': $v.item.infos.width.$error,
                            }"
                            placeholder="0"
                            class="form-control"
                          />
                        </b-input-group>
                      </b-form-group>
                    </div>

                    <div class="col-6">
                      <b-form-group>
                        <label for="">
                          <i class="text-danger bi bi-record-circle"></i>
                          Altura
                        </label>
                        <b-input-group append="cm">
                          <input
                            v-mask="['####']"
                            type="tel"
                            v-model="item.infos.height"
                            :class="{
                              'is-invalid': $v.item.infos.height.$error,
                            }"
                            placeholder="0"
                            class="form-control"
                          />
                        </b-input-group>
                      </b-form-group>
                    </div>

                    <div class="col-6">
                      <b-form-group>
                        <label for="">
                          <i class="text-danger bi bi-record-circle"></i>
                          Comprimento
                        </label>
                        <b-input-group append="cm">
                          <input
                            v-mask="['####']"
                            type="tel"
                            v-model="item.infos.length"
                            :class="{
                              'is-invalid': $v.item.infos.length.$error,
                            }"
                            placeholder="0"
                            class="form-control"
                          />
                        </b-input-group>
                      </b-form-group>
                    </div>
                  </div>
                  <hr />
                </b-col>

                <b-col md="12">
                  <div class="form-row">
                    <div class="col-6">
                      <b-form-group>
                        <label for="">
                          Código NCM
                          <feather-icon
                            icon="HelpCircleIcon"
                            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                            v-b-tooltip.hover.v-dark
                            title="Para descobrir o código NCM de uma mercadoria, consulte a NCM On-line do sistema Classif do Portal Único do Comércio Exterior (Pucomex). A consulta na NCM On-line pode ser feita através de pesquisa por código ou palavras e pela navegação na árvore da NCM."
                            variant="outline-dark"
                          />
                        </label>
                        <b-form-input v-model="item.infos.code_ncm" />
                      </b-form-group>
                    </div>

                    <div class="col-6">
                      <b-form-group>
                        <label for="">
                          Código SKU
                          <feather-icon
                            icon="HelpCircleIcon"
                            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                            v-b-tooltip.hover.v-dark
                            title="Este código é utilizado apenas para o seu controle interno e não será exibido para o cliente."
                            variant="outline-dark"
                          />
                        </label>
                        <b-form-input v-model="item.infos.code_sku" />
                      </b-form-group>
                    </div>

                    <div class="col-md-12">
                      <b-form-group>
                        <label for="">
                          Cód. Barras
                          <feather-icon
                            icon="HelpCircleIcon"
                            v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                            v-b-tooltip.hover.v-dark
                            title="Você pode usar qualquer código de barras: EAN, DataBar, UPC, ITF-14, 128, 25 intercalado, QR code, DataMatrix."
                            variant="outline-dark"
                          />
                        </label>
                        <b-form-input
                          v-model="item.infos.code_bar"
                          placeholder=""
                        />
                      </b-form-group>
                    </div>
                  </div>
                  <hr />
                </b-col>

                <b-col md="12">
                  <div class="form-row">
                    <div class="col-md-4">
                      <b-form-group>
                        <label for=""> Estoque </label>
                        <b-form-input
                          v-model="item.infos.quantity"
                          type="number"
                          min="0"
                          max="99999"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-md-4">
                      <b-form-group>
                        <label for=""> Páginas </label>
                        <b-form-input
                          v-model="item.infos.pages"
                          type="number"
                          min="0"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-md-4">
                      <b-form-group>
                        <label for=""> Ano </label>
                        <b-form-input
                          v-model="item.infos.year"
                          type="number"
                          min="0"
                        />
                      </b-form-group>
                    </div>
                    <div class="col-12">
                      <b-form-checkbox
                        v-model="item.infos.keep_selling"
                        value="1"
                        class="custom-control-primary custom-checkbox-footer"
                      >
                        Vender com estoque zerado
                      </b-form-checkbox>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>

          <b-card class="mb-1" header="Detalhes">
            <b-card-text>
              <div class="row">
                <b-col md="12">
                  <div class="form-group">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Ficha técnica
                    </label>
                    <v-select
                      ref="selectDatasheet"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.datasheet"
                      placeholder="Digite o título"
                      :options="optionsDatasheets"
                      @search="fetchDatasheets"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.selectDatasheet.open = false"
                      >
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </div>
                </b-col>
                <b-col md="12">
                  <div class="form-group">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Tempo de acesso <small>(dias) </small>
                    </label>
                    <input
                      v-mask="['####']"
                      type="tel"
                      min="1"
                      v-model="item.validity"
                      :class="{ 'is-invalid': $v.item.validity.$error }"
                      placeholder="365"
                      class="form-control"
                    />
                  </div>
                </b-col>
                <div class="col-md-12 col-12">
                  <label for=""> Subcategoria </label>
                  <b-form-group>
                    <v-select
                      ref="selectSub"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.subcategory"
                      placeholder="Digite o título"
                      :options="optionsSubcategories"
                      @search="fetchSubcategories"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.selectSub.open = false"
                      >
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </b-form-group>
                </div>
                <div class="col-md-12 col-12">
                  <label for=""> Área(s) </label>
                  <b-form-group>
                    <v-select
                      multiple
                      ref="selectAreas"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.areas"
                      placeholder="Digite o título"
                      :options="optionsAreas"
                      @search="fetchAreas"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.selectAreas.open = false"
                      >
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </b-form-group>
                </div>
                <div class="col-md-12 col-12">
                  <div class="form-group mb-0">
                    <label for=""> Sales force </label>
                    <v-select
                      ref="selectSalesForce"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.salesforce"
                      placeholder="Digite o título"
                      :options="optionsSalesForce"
                      @search="fetchSalesForce"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.selectSalesForce.open = false"
                      >
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-card>
          <b-card class="mb-1" header="Preço">
            <b-card-text>
              <b-row class="form-row">
                <b-col md="12">
                  <b-form-group>
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Preço
                    </label>
                    <money
                      class="form-control"
                      v-model="item.price"
                      placeholder="0,00"
                      v-bind="money"
                      :class="{ 'is-invalid': $v.item.price.$error }"
                    ></money>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label for=""> Promocional </label>
                    <money
                      class="form-control"
                      v-model="item.sale"
                      placeholder="0,00"
                      v-bind="money"
                    ></money>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group class="mb-0">
                    <label for=""> Promoção válida até </label>
                    <flat-pickr
                      v-model="item.promotion_date"
                      class="form-control"
                      :config="{
                        dateFormat: 'd/m/Y',
                        altFormat: 'Y-m-d',
                        locale: 'pt',
                      }"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>

          <b-card class="mb-1" header="Opcionais">
            <b-card-text>
              <b-row class="form-row">
                <div class="col-12 mb-1">
                  <div class="form-group">
                    <label for=""
                      >Depoimento(s) <small>(Aprovados)</small>
                    </label>
                    <v-select
                      multiple
                      ref="selectApproved"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.approveds"
                      placeholder="Digite o título"
                      :options="optionsApproveds"
                      @search="fetchApproveds"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.selectApproved.open = false"
                      >
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </div>
                </div>
                <div class="col-12">
                  <b-form-group>
                    <b-form-checkbox
                      v-model="item.disable_store_discount"
                      value="1"
                      class="custom-control-primary"
                    >
                      Não aplicar desconto em loja
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox
                      v-model="item.disable_billet"
                      value="1"
                      class="custom-control-primary"
                    >
                      Desabilitar venda no boleto
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox
                      v-model="item.disable_popup"
                      value="1"
                      class="custom-control-primary"
                    >
                      Desativar Pop-up de interessado
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox
                      v-model="item.disable_whatsapp"
                      value="1"
                      class="custom-control-primary"
                    >
                      Desativar botão do WhatsApp
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group class="mb-0">
                    <b-form-checkbox
                      v-model="item.disable_form_contact"
                      value="1"
                      class="custom-control-primary"
                    >
                      Desativar formulário de interessado
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </b-row>
            </b-card-text>
          </b-card>

          <b-card class="mb-1" header="Tags">
            <b-card-text>
              <b-row>
                <b-col md="12">
                  <b-form-group class="mb-0">
                    <b-form-tags
                      separator=",;"
                      no-add-on-enter
                      class="form-control"
                      placeholder=""
                      v-model="item.tags"
                      :class="{ 'is-invalid': $v.item.tags.$error }"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <p class="mb-0 mt-2">Adicione tags separando-as por vírgula.</p>
            </b-card-text>
          </b-card>

          <b-card class="mb-1" header="Status">
            <b-card-text>
              <b-form-group class="mb-0">
                <v-select
                  label="title"
                  v-model="item.status"
                  :class="{ 'is-invalid': $v.item.status.$error }"
                  :options="[
                    { title: 'Ativo', code: 1 },
                    { title: 'Inativo', code: 0 },
                  ]"
                />
              </b-form-group>
            </b-card-text>
          </b-card>
          <ButtonsActionsFooter
            variant="success"
            :submited="submited"
            text="Salvar"
            subtext="Aguarde..."
          />
          <p class="mt-2">
            <i class="bi bi-plug"></i> ID Vindi: {{ item.external_id }}
          </p>
        </div>
      </div>
    </b-form>

    <b-modal
      id="modal-product"
      centered
      title="Vincular Produto"
      cancel-title="Fechar"
      ok-title="Vincular"
      ok-only
    >
      <b-form>
        <b-card-text>
          <b-row class="form-row">
            <b-col md="12">
              <b-form-group>
                <label> Digite o título do produto </label>
                <v-select
                  ref="selectProduct"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="book"
                  placeholder="Digite o título"
                  :options="optionsBooks"
                  @search="fetchProducts"
                  @input="productSelected"
                >
                  <span
                    slot="no-options"
                    @click="$refs.selectProduct.open = false"
                  >
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </b-form-group>
            </b-col>
            <div class="col-12" style="min-height: 250px">
              <p>Produtos selecionados:</p>
              <draggable
                tag="ul"
                :list="item.books"
                class="list-group mt-1"
                handle=".handle"
              >
                <li
                  v-for="(element, index) in item.books"
                  :key="index"
                  class="list-group-item"
                >
                  <feather-icon icon="MoveIcon" class="handle" size="15" />

                  <span class="pl-1 text-not-selected"
                    >{{ element.title }}
                  </span>

                  <feather-icon
                    icon="XCircleIcon"
                    class="float-right"
                    size="20"
                    @click="removeAt(index)"
                  />
                </li>
              </draggable>
            </div>
          </b-row>
        </b-card-text>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import draggable from "vuedraggable";
import { mapState } from "vuex";
import {
  BFormTags,
  BFormGroup,
  BForm,
  BRow,
  BImg,
  BCol,
  BCardText,
  BCard,
  VBTooltip,
  VBToggle,
  BAvatar,
  BEmbed,
  BButton,
  BModal,
  BCardFooter,
  BSpinner,
  BListGroup,
  BBadge,
  BListGroupItem,
  BFormCheckbox,
  BFormInput,
  BInputGroup,
} from "bootstrap-vue";
import { Money } from "v-money";
import Ripple from "vue-ripple-directive";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import vSelect from "vue-select";
import "swiper/css/swiper.css";

import Editor from "@tinymce/tinymce-vue";

import { required } from "vuelidate/lib/validators";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";

import { stringToSlug } from "@/_helpers/_urlHelper";

export default {
  components: {
    flatPickr,
    BInputGroup,
    BCard,
    Swiper,
    SwiperSlide,
    BSpinner,
    Money,
    editor: Editor,
    BBadge,
    BFormCheckbox,
    BModal,
    BImg,
    BEmbed,
    BCardText,
    draggable,
    BFormTags,
    BFormInput,
    BFormGroup,
    vSelect,
    BForm,
    BRow,
    BCol,
    BAvatar,
    BButton,
    BCardFooter,
    BListGroup,
    BListGroupItem,
    ButtonsActionsFooter,
  },
  data() {
    return {
      image_url: "",
      submited: false,
      submitedFind: false,
      preview: null,
      user: {},
      snowOption: {
        theme: "snow",
        placeholder: "",
        height: "300",
        modules: {
          clipboard: {
            matchVisual: false,
          },
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction
            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [
              {
                color: [
                  "#00C390",
                  "#031AF5",
                  "#009C73",
                  "#00F7B7",
                  "#4361EE",
                  "#2E49C8",
                  "#1D9EFC",
                  "#1D9EFC",
                  "#000000",
                  "#e60000",
                  "#ff9900",
                  "#ffff00",
                  "#008a00",
                  "#0066cc",
                  "#9933ff",
                  "#ffffff",
                  "#facccc",
                  "#ffebcc",
                  "#ffffcc",
                  "#cce8cc",
                  "#cce0f5",
                  "#ebd6ff",
                  "#bbbbbb",
                  "#f06666",
                  "#ffc266",
                  "#ffff66",
                  "#66b966",
                  "#66a3e0",
                  "#c285ff",
                  "#888888",
                  "#a10000",
                  "#b26b00",
                  "#b2b200",
                  "#006100",
                  "#0047b2",
                  "#6b24b2",
                  "#444444",
                  "#5c0000",
                  "#663d00",
                  "#666600",
                  "#003700",
                  "#002966",
                  "#3d1466",
                  "custom-color",
                ],
              },
              { background: [] },
            ],
            [{ font: [] }],
            [{ align: [] }],
            ["link", "video"],
            ["clean"],
          ],
        },
      },
      snowOptionSimple: {
        theme: "snow",
        placeholder: "",
        height: "200",
        modules: {
          clipboard: {
            matchVisual: false,
          },
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons

            ["clean"],
          ],
        },
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      item: {
        title: "",
        label_image: "",
        description: "",
        about: "",
        validity: 365,
        slug: "",
        price: "",
        promotion_date: '',
        sale: "",
        title_seo: "",
        description_seo: "",
        status: { title: "Ativo", code: 1 },
        areas: [],
        books: [],
        datasheet: "",
        approveds: "",
        subcategory: "",
        disable_store_discount: false,
        disable_billet: false,
        disable_popup: false,
        disable_whatsapp: false,
        product_category_uuid: "",
        infos: {
          weight: "",
          width: "",
          height: "",
          length: "",
          keep_selling: false,
          quantity: 0,
          code_ncm: "",
          code_sku: "",
          code_bar: "",
          pages: "",
          year: "",
          external_id: "",
        },
      },
      optionsDatasheets: [],
      optionsAreas: [],
      optionsSalesForce: [],
      optionsSubcategories: [],
      optionsApproveds: [],
      optionsBooks: [],
      book: "",
      uuid: "",
    };
  },
  watch: {
    "item.slug": {
      handler() {
        this.item.slug = stringToSlug(this.item.slug);
      },
    },
  },
  created() {
    this.uuid = this.$route.params.uuid;

    this.getData();
  },
  computed: {
    ...mapState("Discipline", ["searchDisciplines", "findsDisciplines"]),
    ...mapState("Product", ["searchSubcategories"]),
    ...mapState("Area", ["searchAreas"]),
    ...mapState("Approved", ["searchApproveds"]),
  },
  methods: {
    async getData() {
      this.$store.dispatch("Product/show", this.$route.params.uuid).then((data) => {
        this.item = data;

        this.item.disable_store_discount = this.item.disable_store_discount
          ? 1
          : 0;
        this.item.disable_billet = this.item.disable_billet ? 1 : 0;
        this.item.disable_popup = this.item.disable_popup ? 1 : 0;
        this.item.disable_whatsapp = this.item.disable_whatsapp ? 1 : 0;
        this.item.disable_form_contact = this.item.disable_form_contact ? 1 : 0;
        this.item.infos.keep_selling = this.item.infos.keep_selling ? 1 : 0;
        this.image_url = this.item.image_url;
        this.item.tags = this.item.tags;
      });
    },
    async fetchApproveds(term) {
      if (term.length > 1) {
        this.$store.dispatch("Approved/search", term).then(() => {
          this.optionsApproveds = this.searchApproveds;
        });
      }
    },
    async fetchSubcategories(term) {
      if (term.length > 2) {
        this.$store
          .dispatch("Product/searchSubcategory", {
            uuid: process.env.VUE_APP_PRODUCT_CATEGORY_BOOK_UUID,
            term,
          })
          .then(() => {
            this.optionsSubcategories = this.searchSubcategories;
          });
      }
    },
    async fetchAreas(term) {
      if (term.length > 2) {
        this.$store.dispatch("Area/search", term).then(() => {
          this.optionsAreas = this.searchAreas;
        });
      }
    },
    async fetchDatasheets(term) {
      if (term.length > 2) {
        this.optionsDatasheets = await this.$store.dispatch("Datasheet/search", term)
      }
    },
    async fetchSalesForce(term) {
      if (term.length > 2) {
        this.$store.dispatch("SalesForce/search", term).then((response) => {
          this.optionsSalesForce = response;
        });
      }
    },
    previewImage(event) {
      const input = event.target;
      if (input.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        const file = input.files[0];
        this.item.image = file;
        reader.readAsDataURL(file);
      }
    },
    removeAt(idx) {
      this.item.books.splice(idx, 1);
    },
    submitForm() {
      this.$v.$touch();
      this.submited = true;

      const formData = new FormData();
      formData.append("image", this.item.image);
      formData.append("title", this.item.title);
      formData.append("title_seo", this.item.title_seo);
      formData.append("validity", this.item.validity);
      formData.append("price", this.item.price);
      formData.append("sale", this.item.sale);
      formData.append("id_old", this.item.id_old ?? '');
      formData.append("description", this.item.description);
      formData.append("about", this.item.about ?? "");
      formData.append("description_seo", this.item.description_seo);
      formData.append("label_image", this.item.label_image);
      formData.append("video_url", this.item.video_url ?? "");
      formData.append("promotion_date", this.item.promotion_date ?? "");
      formData.append(
        "datasheet",
        this.item.datasheet ? this.item.datasheet.code : ""
      );
      formData.append(
        "approveds",
        this.item.approveds ? JSON.stringify(this.item.approveds) : []
      );
      formData.append(
        "areas",
        this.item.areas ? JSON.stringify(this.item.areas) : []
      );
      formData.append(
        "subcategory",
        this.item.subcategory ? this.item.subcategory.code : ""
      );
      formData.append(
        "disable_store_discount",
        this.item.disable_store_discount ? 1 : 0
      );
      formData.append("disable_billet", this.item.disable_billet ? 1 : 0);
      formData.append("disable_popup", this.item.disable_popup ? 1 : 0);
      formData.append(
        "disable_form_contact",
        this.item.disable_form_contact ? 1 : 0
      );
      formData.append(
        "tags",
        this.item.tags ? JSON.stringify(this.item.tags) : ""
      );
      formData.append("status", this.item.status ? this.item.status.code : "");
      formData.append("slug", this.item.slug);
      formData.append(
        "disciplines",
        this.item.disciplines ? JSON.stringify(this.item.disciplines) : []
      );
      formData.append(
        "books",
        this.item.books ? JSON.stringify(this.item.books) : []
      );
      formData.append(
        "infos",
        this.item.infos ? JSON.stringify(this.item.infos) : []
      );
      formData.append(
        "product_category_uuid",
        process.env.VUE_APP_PRODUCT_CATEGORY_BOOK_UUID
      );
      formData.append(
        "salesforce",
        this.item.salesforce ? this.item.salesforce.code : ""
      );

      if (!this.$v.$error) {
        this.$store
          .dispatch("Product/update", { uuid: this.uuid, data: formData })
          .then(() => {
            this.$router.push({ name: "products-books-list" });
            this.notifyDefault("success");
          })
          .catch((err) => {
            this.submited = false;
            this.notifyDefault("error");
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    productSelected(event) {
      if (event) {
        this.optionsBooks = "";
        this.book = "";

        if (!this.item.books.find((element) => element.code === event.code)) {
          this.item.books.push({
            code: event.code,
            title: event.title,
          });
        }
      }
    },
    async fetchProducts(term) {
      this.optionsBooks = [];
      if (term.length > 2) {
        this.$store
          .dispatch("Product/search", { term: term, type: 6 })
          .then((res) => {
            this.optionsBooks = res;
          });
      }
    },
  },
  validations: {
    item: {
      title: {
        required,
      },
      price: {
        required,
      },
      description: {
        required,
      },
      // datasheet: {
      //   required,
      // },
      label_image: {
        required,
      },
      // areas: {
      //   required,
      // },
      // subcategory: {
      //   required,
      // },
      validity: {
        required,
      },
      tags: {
        required,
      },
      status: {
        required,
      },
      // salesforce: {
      //   required,
      // },
      infos: {
        weight: {
          required,
        },
        width: {
          required,
        },
        height: {
          required,
        },
        length: {
          required,
        },
        quantity: {
          required,
        },
        external_id: {
          required,
        },
      },
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
};
</script>
<style lang="scss" scoped>

</style>
<style>
.custom-checkbox-footer label {
  font-size: 0.9rem !important;
}
.container-uploads svg {
  width: 70px;
}
.b-form-tags.form-control {
  padding: 1rem 1rem !important;
}
.beforeUpload .icon {
  width: 50px !important;
}
.area-lessons {
  min-height: 400px;
}
</style>
